<template>
    <div id="online-container">
        <component-education-navbar></component-education-navbar>
<!--        <img src="../../assets/images/education/education_online_bg.png" alt="">-->
        <div class="content">
            <router-view></router-view>
            <component-education-bottom :showScratchBg="showScratchBg" :showBgColor="showScratchBg"></component-education-bottom>
        </div>
    </div>
</template>
<script>
    import ComponentEducationNavbar from '@/components/ComponentEducationNavbar'
    import ComponentEducationBottom from '@/components/ComponentEducationBottom'
    export default {
        name: "Online",
        components: {
            ComponentEducationNavbar,
            ComponentEducationBottom,
        },
        data() {
            return {
                showScratchBg: true

            };
        },
        watch: {
            "$route.name": function(newVal) {
                newVal=== 'OnlineList' ? this.showScratchBg = false : this.showScratchBg = true;
            }
        },
        mounted() {
            this.$route.name === 'OnlineList' ? this.showScratchBg = false : this.showScratchBg = true;
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #online-container {
        min-width: 1380px;
        @{deep} .topic {
            width: 160px;
            margin: 0 auto 3%;
        }
        @{deep} .btn-more {
            display: block;
            width: 140px;
            height: 40px;
            line-height: 40px;
            margin: 3% auto;
            font-size: 18px;
            border-radius: 50px;
            text-align: center;
            background: linear-gradient(to right, #24CEFF, #1BB2FF);
            box-shadow: 0 2px 15px 4px rgba(27, 178, 255, 0.5);
            color: #fff;
            box-sizing: border-box;
        }
        >img {
            background-color: #F2FCFF;
        }
        .btn-join {
            width: 160px;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
        }
        .content {
            height: 100%;
            box-sizing: border-box;
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #online-container {
            min-width: 320px;
            padding-top: 80px;
            background: linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -moz-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -webkit-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -o-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -ms-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            &>img{
                display: block;

            }

             @{deep} .btn-more{
                .fontFn(14);
                .fontFn(120,width);
                .fontFn(40,height);
                .fontFn(40,line-height);
            }
        }
    }
</style>